import React, { Component } from 'react'

export default class TheShift extends Component {

    componentDidMount(){
        window.location.href = 'https://the-shift-by-found-stages.eventbrite.com';
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}